<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">The conversion of nitrogen gas and hydrogen gas to ammonia is shown below.</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="N2(g) + 3 H2(g) <=> 2 NH3(g)" />
      </p>

      <p class="mb-2">
        The equilibrium constant,
        <stemble-latex content="$\text{K}_\text{p},$" />
        for the reaction is
        <stemble-latex content="$8.06$" />
        at
        <stemble-latex content="$185^\circ\text{C}.$" />
        Determine the value of
        <stemble-latex content="$\text{K}_\text{c}$" />
        for the reaction at this temperature.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question107',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    Temp() {
      return this.taskState.getValueBySymbol('Temp').content;
    },
    freqFactor() {
      return this.taskState.getValueBySymbol('freqFactor').content;
    },
  },
};
</script>
